@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;700&display=swap');

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: 'Noto Sans TC', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  color: #1F272E;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;

  @media (min-width: 1024px) {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.4;
  }
}

.caption {
  font-size: 0.75rem;
  font-weight: normal;
  line-height: 1.4;

  @media (min-width: 1024px) {
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.4;
  }
}

p {
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.4;

  @media (min-width: 1024px) {
    font-size: 1.125rem;
    font-weight: normal;
    line-height: 1.7;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1.25;

  @media (min-width: 1024px) {
    font-size: 2.75rem;
    font-weight: bold;
    line-height: 1.25;
  }
}

h2 {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.4;

  @media (min-width: 1024px) {
    font-size: 2.25rem;
    font-weight: bold;
    line-height: 1.25;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}